import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout/Layoutexport"
import { StaticImage } from "gatsby-plugin-image"

import { Link } from 'gatsby'

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import LogoUni from "../svg/LogoUni";


import Button from '../components/Button';


import { ArrowLeftIcon, CheckCircleIcon } from '@heroicons/react/solid'

export default function productTemplate({
  location,
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark

  const slides = {

    slide1: getImage(frontmatter.featuredImage),
    slide2: '',
    slide3: '',

  }

  if(frontmatter.image1.relativePath !== 'no-foto.png'){

    slides.slide2 = getImage(frontmatter.image1)

  }

  if(frontmatter.image2.relativePath !== 'no-foto.png'){

    slides.slide3 = getImage(frontmatter.image2)

  }


  var count = 1;
  var showCarousel = false;

  const showSlides = {

    showSlide1: true,

  }

  if(slides.slide2 === ''){
    showSlides.showSlide2 = false;
  } else {
    showSlides.showSlide2 = true;
    count++;
  }

  if(slides.slide3 === ''){
    showSlides.showSlide3 = false;
  } else {
    showSlides.showSlide3 = true;
    count++;
  }

  if(count > 1)
    showCarousel = true;

  const siteVerOpts = [

    {
      label: 'Internacional',
      value: '',


    },

    {
      label: 'Ecuador',
      value: '/',


    },
];

  const langOpts = [


    {
      label: 'ES',
      value: '',

    },


    {
      label: 'EN',
      value: `/export/en/products/`,

    },


  ];

  const defaultNoImage = (

    <div className="absolute inset-0 bg-gradient-to-r from-cyan-500 to-blue-500 flex">
      <div className="grow"></div>
      <LogoUni className="grow-0 w-40 opacity-90 animate-[spin_1s_ease-in-out]" />
      <div className="grow"></div>
    </div>



  );

  return (



    <Layout location={location} crumbLabel={frontmatter.title}
    siteVerOpts={siteVerOpts} langOpts={langOpts}

    title={frontmatter.title} desc={excerpt} banner={`${frontmatter.slug}.jpg`} pathname={frontmatter.path}
    product category={frontmatter.category} categorySlug={frontmatter.categorySlug} productSku={frontmatter.sku} productGtin13={frontmatter.gtin13} offerPrice={frontmatter.price} brandName={frontmatter.brandName} brandLogo={frontmatter.brandLogo}>

    <section id="product-detail" className="text-gray-700 body-font overflow-hidden bg-white">
      <div className="container px-5 py-4 mx-auto">
        <div className="lg:w-4/5 mx-auto flex flex-wrap">
          <div className="lg:w-1/2 w-full">
            {showCarousel
              ?
                  <AwesomeSlider className="aws-btn" style={{ "--slider-height-percentage": "90%",
                                                              "--organic-arrow-height": "20px",
                                                              "--control-button-opacity": "0.3",
                                                              "--control-bullet-color": "#FFFFFF",
                                                              "--control-bullet-active-color": "#FFFFFF",
                                                              "--organic-arrow-color": "#FFFFFF",
                                                              "--organic-arrow-border-radius": "2",
                                                              "--control-button-hover-opacity": "0.5",
                                                            }}>
                    <div>
                        <GatsbyImage image={slides.slide1} className="h-fit" alt="Haga click para agrandar" />

                    </div>
                    {showSlides.showSlide2 ?
                      <div>
                          <GatsbyImage image={slides.slide2} className="h-fit" alt="Haga click para agrandar" />
                      </div>
                    : <div className="w-full h-full relative">{defaultNoImage}</div> }
                    {showSlides.showSlide3 ?
                      <div>
                          <GatsbyImage image={slides.slide3} className="h-fit" alt="Haga click para agrandar" />
                      </div>
                    : <div className="w-full h-full relative">{defaultNoImage}</div> }
                  </AwesomeSlider>
              :
                <GatsbyImage image={slides.slide1} className="h-fit" alt="Haga click para agrandar" />
              }


          </div>
          <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <h2 className="text-sm title-font text-gray-500 tracking-widest">{frontmatter.category}</h2>
            <h1 className="text-primary-default text-3xl title-font font-medium mb-1">{frontmatter.title}</h1>
            <h3 className="text-secondary-default text-md font-medium mb-1">{frontmatter.subtitle}</h3>
            <div className="flex mb-4">

                <span className="text-gray-600 ml-3">{frontmatter.sku}</span>


            </div>
            <div className="leading-relaxed border-b-2 border-gray-200 mb-5 pb-4" dangerouslySetInnerHTML={{ __html: html }}/>

            <div className="flex">

              <Link to={'/export/es/'+frontmatter.categorySlug} className="bg-secondary-lighter p-2 rounded-lg text-white hover:no-underline hover:text-white hover:bg-primary-lighter">
                Ver más productos
              </Link>
             
            </div>
          </div>
        </div>
      </div>
    </section>


    <section id="value-proposition" className="bg-gray-200" aria-labelledby="contact-heading">
      <div className="w-full mx-auto mt-32  max-w-7xl pb-20 pt-4 px-4 sm:px-6 lg:px-8">
        <div className=" my-24">
          <h2 className="text-primary-lighter text-center font-semibold text-5xl">- Nuestra Propuesta de Valor -</h2>
        </div>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-4 lg:gap-y-0 lg:gap-x-6">
          <div className="flex flex-col bg-gray-100 rounded-2xl shadow-xl">
            <div className="flex-1 relative pt-14 px-4 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-secondary-lighter rounded-xl shadow-lg transform -translate-y-1/2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>

              </div>
              <h3 className="text-3xl font-semibold text-primary-lighter text-center ">Calidad de clase mundial</h3>
              <p className="mt-4 text-lg text-primary-default">Somos fabricantes certificados con más de 25 años de experiencia. Todos nuestros productos son fabricados bajo procesos de Buenas Prácticas de Manufactura.</p>
            </div>
          </div>

          <div className="flex flex-col bg-white rounded-2xl shadow-xl bg-gray-100">
            <div className="flex-1 relative pt-16 px-4 pb-8 md:px-8 pt-14">
              <div className="absolute top-0 p-5 inline-block bg-secondary-lighter rounded-xl shadow-lg transform -translate-y-1/2 ">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>
              </div>
              <h3 className="text-3xl font-semibold text-primary-lighter text-center ">Cercanía</h3>
              <p className="mt-4 text-lg text-primary-default">Estamos ubicados en el centro del mundo, y con capacidad para suministrar nuestros productos a toda la región, con un lead time hasta 3 veces menor al requerido para traer mercancías desde Asia.</p>
            </div>
          </div>

          <div className="flex flex-col bg-white rounded-2xl shadow-xl bg-gray-100">
            <div className="flex-1 relative pt-16 px-4 pb-8 md:px-8 pt-14">
              <div className="absolute top-0 p-5 inline-block bg-secondary-lighter rounded-xl shadow-lg transform -translate-y-1/2">

              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                 <path stroke-linecap="round" stroke-linejoin="round" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" /></svg>
              </div>
              <h3 className="text-3xl font-semibold text-primary-lighter text-center">Crédito</h3>
              <p className="mt-4 text-lg text-primary-default">Contamos con mecanismos de financiamiento para otorgar crédito a nuestros clientes internacionales desde su primera compra.</p>
            </div>
          </div>
          <div className="flex flex-col bg-white rounded-2xl shadow-xl bg-gray-100">
            <div className="flex-1 relative pt-16 px-4 pb-8 md:px-8 pt-14">
              <div className="absolute top-0 p-5 inline-block bg-secondary-lighter rounded-xl shadow-lg transform -translate-y-1/2">

              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" /></svg>
              </div>
              <h3 className="text-3xl font-semibold text-primary-lighter text-center">Soporte y acompañamiento</h3>
              <p className="mt-4 text-lg text-primary-default">Damos soporte a nuestros clientes mediante capacitación de su fuerza de ventas, asesoramiento y acompañamiento en la definición e implementación de estrategias.</p>
            </div>
          </div>

        </div>
      </div>
    </section>


    <section id="sellos de calidad" className="bg-blue-900 py-16">
      <div className="container mx-auto sm:flex ">
        <div className=" w-full sm:flex-1 ">
          <h2 className="  pl-5 mx-auto text-3xl font-semibold lg:text-left sm:text-center text-white pb-4 pt-10">
          Cumplimos con certificaciones nacionales e internacionales que avalan la calidad de nuestros productos:
          </h2>
       </div>
        <div className="w-full sm:flex-1 ">
         <div className="grid grid-cols-4 gap-4  pl-10 pr-10">
           <div class="flex flex-grow flex-shrink justify-center">
              <StaticImage src="../images/logo-issa.jpg"
                        className="flex justify-center my-4 rounded-full"
                        alt="Cumplimos con la certificacion ISSA"
                        placeholder="blurred"
                        layout="constrained"/>
          </div>
            <div className="flex flex-grow flex-shrink justify-center">
              <StaticImage src="../images/logo-iso.jpg"
                        className="flex justify-center my-4 rounded-full"
                        alt="Cumplimos con la certificación ISO"
                        placeholder="blurred"
                        layout="constrained"/>
            </div>
            <div className="flex flex-grow flex-shrink justify-center">
              <StaticImage src="../images/sello-bpm.jpg"
                        className="flex justify-center my-4 rounded-full"
                        alt="Cumplimos con el sello de buenas prácticas de manufactura"
                        placeholder="blurred"
                        layout="constrained"/>
            </div>
            <div className="flex flex-grow flex-shrink justify-center">
              <StaticImage src="../images/sello-mucho-mejor-ecuador.jpg"
                        className="flex justify-center my-4 rounded-full"
                        alt="Cumplimos con el sello de Mucho Mejor Ecuador "
                        placeholder="blurred"
                        layout="constrained"/>
            </div>
          </div>
        </div>
      </div>
    </section>

<section id="contacto" className="">
<div class="relative bg-secondary-lighter">
  <div class="h-56 bg-secondary-lighter sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../images/call-center-unilimpio.jpg"
                      className=" object-cover"
                      alt="Solicite una cotización con Unilimpio"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div class="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-20">
    <div class="md:ml-auto md:w-1/2 md:pl-10">
      <h2 class="mt-4 text-xl text-4xl font-bold text-white text-center">¡SOLICITE UNA COTIZACIÓN AHORA!</h2>
      <p class="mt-6 text-2xl text-left font-normal text-white">Gracias por su interés en Unilimpio S.A. Estaremos gustosos de atenderle para brindarle mayor información acerca de nuestros productos de higiene profesional.</p>
      <div class="mt-8">
      <div className="flex flex-wrap mx-auto justify-center pt-4">
         <Link to="/export/es/contacto/">
          <Button className=" text-primary-lighter text-semibold text-xl  rounded-xl transition duration-200 ease-in-out bg-white shadow-md hover:bg-white hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none" size="s" type="submit">Contáctese</Button>
         </Link>
       </div>
      </div>
    </div>
  </div>
</div>
</section>









    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: {path: {eq: $path}}) {
    frontmatter {
      date(formatString: "DD/MM/YYYY")
      slug
      path
      featuredImage {
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      category
      title
      subtitle
      pageLang
      sku
      price
      gtin13
      brandName
      brandLogo
      categorySlug
      image1 {
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: BLURRED, layout: CONSTRAINED)
        }
        relativePath
      }
      image2 {
        childImageSharp{
          gatsbyImageData(width: 800, placeholder: BLURRED, layout: CONSTRAINED)
        }
        relativePath
      }
    }
    excerpt
    html
  }
  }
  `
