import React from 'react';
import { Link } from "gatsby";
import SocialIcons from "../SocialIcons";
import LogoUni from "../../svg/LogoUni";
import { StaticImage } from "gatsby-plugin-image"

let currentYear =  new Date().getFullYear();
let copyrightString = `© 1999-${currentYear} Unilimpio - Todos los derechos reservados`;


const Footer = () => (

  <footer className="text-white bg-primary-normal pt-16">


  <div className="container px-5 pb-24 pt-4 mx-auto z-0">
    <div className="flex flex-wrap md:text-left text-center">
      <div className="lg:w-1/6 md:w-1/2 w-full px-4">
        <div className="w-28 lg:w-30 mx-auto mb-8"><LogoUni/></div>

      </div>
      <div className="lg:w-1/4 sm:w-1/2 w-full px-4 text-center sm:text-left">
        <h3 className="text-lg font-bold tracking-widest mb-3">CONTÁCTENOS</h3>
        <nav className="list-none mb-10">
        <ul>
          <li className="text-white">
            De los Eucaliptos E1-140<br/>
            y pasaje Juncos<br/>
            170307<br/>
            Quito, Ecuador
          </li>
          <li className="text-white">
            Consultas, información, sugerencias: <Link to="/export/es/contacto/" className="text-white underline hover:no-underline">escríbanos</Link>.
          </li>
          </ul>
        </nav>
      </div>

      <div className="lg:w-1/6 md:w-1/2 w-full px-4">
        <h3 className="title-font font-bold tracking-widest text-sm mb-3">PRODUCTOS</h3>
          <nav className="list-none mb-10">
          <ul>
            <li>
            <Link to="/export/es/dispensador-toalla-de-manos-interfoliada" className="text-white hover:text-gray-400">Dispensador Toalla de Manos Interfoliada</Link>
            </li>
            <li>
            <Link to="/export/es/dispensador-jabon" className="text-white hover:text-gray-400">Dispensador Jabón</Link>
            </li>
            <li>
            <Link to="/export/es/dispensador-gel-antibacterial" className="text-white hover:text-gray-400">Dispensador Gel</Link>
            </li>
            <li>
            <Link to="/export/es/jabon-liquido-antibacterial" className="text-white hover:text-gray-400">Jabón Líquido antibacterial</Link>
            </li>
            <li>
            <Link to="/export/es/gel-antibacterial" className="text-white hover:text-gray-400">Gel Antibacterial</Link>
            </li>
            <li>
              <Link to="/export/es/bacterac" className="text-white hover:text-gray-400"> Desinfectante con Amonio cuaternario</Link>
            </li>
            </ul>
          </nav>


      </div>
      <div className="lg:w-1/6 md:w-1/2 w-full px-4">
      <h3 className="title-font font-bold tracking-widest text-sm mb-3">ACERCA DE</h3>
        <nav className="list-none mb-10">
        <ul>
          <li>
           <Link to="/export/es/acerca-de/" className="text-white hover:text-gray-400"> Sobre Nosotros </Link>
          </li>
          <li>
           <Link to="/export/es/responsabilidad-social" className="text-white hover:text-gray-400"> Responsabilidad Social</Link>
          </li>
          <li>
            <Link to="/export/es/denuncias-actos-de-corrupcion" className="text-white hover:text-gray-400">Denuncia actos de corrupción</Link>
          </li>
          </ul>
        </nav>
        <h3 className="title-font font-bold text-secondary tracking-widest text-sm mb-3">SÍGANOS EN REDES SOCIALES</h3>
        <SocialIcons className="flex flex-row justify-center sm:justify-start my-2  text-center sm:text-left" color="#FFFFFF"/>
      </div>

      <div className="lg:w-1/6 md:w-1/2 w-full px-4">


      </div>
    </div>
  </div>

  <div className="bg-zinc-500 text-gray-200">
      <div className="container py-4 px-5 flex flex-col sm:flex-row sm:justify-between">
        <div className="flex flex-col">
          <p className="text-sm text-center sm:text-left">
            {copyrightString}
          </p>
          <p className="text-sm text-center sm:text-left">
            <Link className="flex-col sm:flex-row text-primary-xlighter hover:text-gray-200" to="/legal/terminos-y-condiciones">Términos y condiciones de uso</Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="flex-col sm:flex-row text-primary-xlighter hover:text-gray-200" to="/legal/politica-de-privacidad">Política de privacidad</Link>
          </p>
          <p className="sm:text-left text-center text-sm">Unilimpio es una empresa que cuenta con un sistema de gestión de la calidad certificado por ICONTEC.</p>
        </div>
        <div className="flex items-center justify-center sm:justify-start mt-3 sm:mt-0">
          <StaticImage
            src="../images/logo-issa.jpg"
            alt="Logo ISSA"
            placeholder="blurred"
            layout="constrained"
            className="w-16 h-16 mr-2 rounded-full"
          />
          <StaticImage
            src="../images/logo-iso.jpg"
            alt="Logo ISO"
            placeholder="blurred"
            layout="constrained"
            className="w-16 h-16 rounded-full"
          />
        </div>




    </div>
  </div>
</footer>
);

export default Footer;
