import React from 'react';

import Scroll from '../Scroll';



import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

import Footer from './Footerexport';
import Header from './Headerexport';

import { LazyMotion, domAnimation } from "framer-motion"

import CookieConsent from 'react-cookie-consent';



const Layout = ({location, crumbLabel,
  siteVerOpts, langOpts, alternates, alternateEn, alternateEs, alternateEsec, alternateDefault,
  title, desc, banner, pathname,
  article, articleDate, product, category, categorySlug, productSku, productGtin13, offerPrice, brandName, brandLogo,
  children }) => {



  return (
    <>
      <Header siteVerOpts={siteVerOpts} langOpts={langOpts}
        alternates={alternates} alternateEn={alternateEn} alternateEs={alternateEs} alternateEsec={alternateEsec} alternateDefault={alternateDefault}
       title={title} desc={desc} banner={banner} pathname={pathname} article={article} articleDate={articleDate} product={product} category={category} categorySlug={categorySlug} productSku={productSku} productGtin13={productGtin13} offerPrice={offerPrice} brandName={brandName} brandLogo={brandLogo} />
        <main id="">
          <div className="ml-2">

            <Breadcrumb location={location} crumbLabel={crumbLabel} crumbSeparator=">" />

          </div>



        <Scroll showPoint={800} />
        <LazyMotion features={domAnimation}>
        
        <CookieConsent        
          
          style={{ background: "#545454", opacity: 0.9 }}
          
          buttonStyle={{ background: "#76bd25", fontWeight: "semibold", color: "#fefffe", rounded: "lg" }}
          contentStyle={{fontSize: "12px"}}
          
          setDeclineCookie={true}
          enableDeclineButton={true}
          buttonText="Aceptar"
          declineButtonText="Rechazar"
          cookieName="gatsby-gdpr-google-analytics"
          location="bottom"
          flipButtons={true}>
            <span className="font-bold text-primary-xlighter text-lg">
              Consentimiento para el tratamiento de datos personales
            </span><br/>
            Al usar este sitio web usted acepta haber leído y aceptado los términos de nuestra política de recopilación y uso de datos personales.
            Esto nos permite recopilar información relacionada a sus preferencias de navegación 
            con el fin de mejorar su experiencia de usuario. Nos comprometemos y garantizamos que sus datos serán tratados de manera confidencial y protegidos según las disposiciones legales vigentes. 
        </CookieConsent>
              
              {children}
            </LazyMotion>
        </main>
      <Footer />
    </>
  );
};

export default Layout;
